<template>
  <div>
    <v-dialog persistent :max-width="500" v-model="modal">
      <v-card>
        <v-btn icon @click="cerrar()" color="secondary">
          <v-icon> mdi-window-close </v-icon>
        </v-btn>
        <v-row class="ma-2">
          <p class="text-h5 text-left mx-4 px-4">Agregar convenio</p>
        </v-row>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                class="mx-4"
                label="Fuente de financiamiento*"
                outlined
                v-model="form.fuente"
                :items="ctl_fuente_financiamiento"
                item-text="nombre"
                item-value="id"
                :error-messages="fuenteErrors"
                @blur="$v.form.fuente.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="mx-4"
                label="Código*"
                outlined
                :maxlength="3"
                v-model="form.codigo"
                :error-messages="codigoErrors"
                @blur="$v.form.codigo.$touch()"
                @input="$v.form.codigo.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mx-4"
                label="Convenio*"
                outlined
                auto-grow
                :maxlength="250"
                v-model="form.nombre"
                :error-messages="nombreErrors"
                @blur="$v.form.nombre.$touch()"
                @input="$v.form.nombre.$touch()"
                counter="250"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <section>
            <div class="d-flex justify-center mb-4">
              <v-btn text color="secondary" @click="cerrar()" outlined>
                Cancelar
              </v-btn>
              <v-col cols="1" />
              <v-btn color="secondary" @click="aceptar()"> Aceptar </v-btn>
            </div>
          </section>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
  required,
  maxLength,
  helpers,
  numeric,
} from "vuelidate/lib/validators";

const txtInput = helpers.regex('txtInput', /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 '"\n()/.,:;-]*$/)

export default {
  name: "ConvenioFormComponent",
  props: {
    modal: {
      type: Boolean,
    },
  },
  validations: {
    form: {
      codigo: {
        required,
        numeric,
        maxLength: maxLength(3),
      },
      nombre: {
        required,
        txtInput,
        maxLength: maxLength(75),
      },
      fuente: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      id: null,
      codigo: null,
      nombre: null,
      fuente: null,
    },
  }),
  methods: {
    ...mapActions("convenioStore", ["getListaConvenios", "storeConvenio"]),
    ...mapActions("fuenteFinanciamientoStore", ["getCtlFuenteFinanciamiento"]),
    async aceptar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const body = {
            codigo: this.form.codigo,
            nombre: this.form.nombre,
            id_fuente_financiamiento: this.form.fuente,
            id_tipo_financiacion: 2,
            anio: this.anioFiscal,
          };
          const response = await this.storeConvenio(body);
          this.temporalAlert({
            show: true,
            type: "success",
            message: this.form.id
              ? "Se actualizó la información del convenio"
              : "Convenio registrado",
          });
          await this.getListaConvenios({
            id_institucion: this.idIntitucion,
          });
          this.cerrar();
        } catch (error) {}
      } else {
      }
    },

    cerrar() {
      this.$emit("cerrarModal");
      this.formClean();
    },
    formClean() {
      this.form.id = null;
      this.form.codigo = null;
      this.form.nombre = null;
      this.form.fuente = null;

      this.$v.$reset();
    },
  },
  computed: {
    ...mapState("fuenteFinanciamientoStore", ["ctl_fuente_financiamiento"]),
    ...mapState({
      idIntitucion: (state) => state?.selectedUnidad?.instituciones?.id,
      anioFiscal: (state) => state?.anioFiscal,
    }),
    codigoErrors() {
      const errors = [];
      if (!this.$v.form.codigo.$dirty) return errors;
      !this.$v.form.codigo.required && errors.push("Codigo es obligatorio");
      !this.$v.form.codigo.maxLength &&
        errors.push("Longitud máxima de 3 caracteres");
      !this.$v.form.codigo.numeric &&
        errors.push("El código debe ser de tipo númerico");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required && errors.push("Nombre es obligatorio");
      !this.$v.form.nombre.maxLength &&
        errors.push("Longitud máxima de 25 caracteres");
      !this.$v.form.nombre.txtInput &&
        errors.push("Permite solo caracteres alfabéticos");
      return errors;
    },
    fuenteErrors() {
      const errors = [];
      if (!this.$v.form.fuente.$dirty) return errors;
      !this.$v.form.fuente.required && errors.push("Fuente es obligatorio");
      return errors;
    },
  },
  async created() {
    await this.getCtlFuenteFinanciamiento();
  },
};
</script>
