<template>
  <section>
    <v-btn class="secondary my-5" dark @click="agregar()" v-if="!haveRole('ROLE_DIRECCION_COMPRAS')"> Agregar </v-btn>
    <DataTableComponent
      :headers="tituloCampos"
      :items="listadoOrganismos"
      @paginar="paginate"
      v-models:pagina="paginaModel"
      v-models:select="porPaginaModel"
      :total_registros="filters.total_rows"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="eliminar(item)"  :disabled="haveRole('ROLE_DIRECCION_COMPRAS')">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </DataTableComponent>

    <!-- MODAL AGREGAR -->
    <OrganismoFinanciadorFormComponent
      :modal="modalAgregar"
      @cerrarModal="cerrarModal()"
    />
    <!-- MODAL ELIMINAR -->
    <ConfirmationDialogComponent
      :show="modalEliminar"
      title="¿Desea remover el organismo financiador seleccionado ?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="cerrarModalEliminar()"
      @confirm="eliminarOrganismoFinanciador()"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AppPaginationComponent from "../../../../components/AppPaginationComponent.vue";
import OrganismoFinanciadorFormComponent from "./OrganismoFinanciadorFormComponent";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";
import DataTableComponent from "../../../../components/DataTableComponent.vue";
export default {
  name: "OrganismoFinanciadorListComponent",
  components: {
    OrganismoFinanciadorFormComponent,
    ConfirmationDialogComponent,
    AppPaginationComponent,
    DataTableComponent,
  },
  data: () => ({
    id: null,
    modalAgregar: false,
    modalEliminar: false,
    reset: false,
    tituloCampos: [
      { text: "Código", value: "codigo" },
      {
        text: "Organismo financiador",
        value: "nombre",
      },
      {
        text: "Fuente de financiamiento",
        value: "fuente_financiamiento",
      },

      { text: "Acciones", value: "acciones", align: "center" },
    ],
  }),

  computed: {
    ...mapState("organismoStore", ["listadoOrganismos", "filters"]),
    ...mapState({
      idIntitucion: (state) => state?.selectedUnidad?.instituciones?.id,
    }),

    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },

  methods: {
    ...mapActions("organismoStore", ["getListaOrganismos", "deleteOrganismo"]),
    agregar() {
      this.modalAgregar = true;
    },
    eliminar(item) {
      this.id = item.id;
      this.modalEliminar = true;
    },
    async eliminarOrganismoFinanciador() {
      const response = await this.deleteOrganismo(this.id);
      if (response.status === 204) {
        this.temporalAlert({
          show: true,
          message: "Organismo financiador eliminado",
          type: "success",
        });
        await this.getListaOrganismos({id_institucion: this.idIntitucion});
      }
      this.cerrarModalEliminar();
    },
    cerrarModal() {
      this.modalAgregar = false;
    },
    cerrarModalEliminar() {
      this.modalEliminar = false;
      this.formClean();
    },
    formClean() {
      this.id = null;
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      const filtros = {
        page: pagina,
        per_page: cantidad_por_pagina,
        id_institucion: this.idIntitucion,
      };
      this.getListaOrganismos(filtros).then(() => (this.reset = false));
    },
  },
  async created() {
    await this.getListaOrganismos({id_institucion: this.idIntitucion});
  },
};
</script>

<style></style>
