<template>
  <section>
    <!-- <v-row>
      <v-col cols="6">
        <p class="text-h6 primary--text my-0">
          Configuración de techo presupuestario
        </p>
        <small class="my-0">Institución</small>
        <p class="my-0">Ministerio de salud</p>
      </v-col>
      <v-col class="offset-xl-4 offset-lg-3 offset-md-2">
        <v-btn
          class="ma-2"
          outlined
          color="secondary"
          depressed
          @click="$router.go(-1)"
        >
          Regresar
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-center flex-sm-row justify-sm-space-between">
        <div>
          <p class="text-h6 primary--text my-0">
            Configuración de techo presupuestario
          </p>
          <small class="my-0">Institución</small>
          <p class="my-0">Ministerio de salud</p>
        </div>
        <div>
          <v-btn
            class="my-2"
            outlined
            color="secondary"
            depressed
            @click="$router.go(-1)"
          >
            Regresar
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- contenedor dinamico de tabs -->
    <v-tabs
      v-model="tab"
      color="primary-text"
      class="mt-5"
      background-color="bgMinsal"
      show-arrows
    >
      <v-tab v-for="item in items" :key="item.tab">
        <v-icon small class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="slide-y-transition"
        reverse-transition="fade-transition"
        v-for="item in items"
        :key="item.tab"
      >
        <v-card flat color="bgMinsal">
          <component :is="item.content" class="py-7"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import EstructuraPresupuestariaListComponent from "./components/EstructuraPresupuestariaListComponent";
import LineaTrabajoListComponent from "./components/LineaTrabajoListComponent";
import FuenteFinanciamientoListComponent from "./components/FuenteFinanciamientoListComponent";
import OrganismoFinanciadorListComponent from "./components/OrganismoFinanciadorListComponent";
import ConvenioListComponent from "./components/ConvenioListComponent";
export default {
  name: "configuracionTechoPresupuestarioView",
  components: {
    EstructuraPresupuestariaListComponent,
    LineaTrabajoListComponent,
    FuenteFinanciamientoListComponent,
    OrganismoFinanciadorListComponent,
    ConvenioListComponent,
  },
  data: () => ({
    tab: null,
    items: [],
  }),
  created() {
    this.items = [
      {
        tab: "Estructuras presupuestarias",
        content: "EstructuraPresupuestariaListComponent",
        icon: "mdi-cash-multiple",
      },
      {
        tab: "Líneas de trabajo",
        content: "LineaTrabajoListComponent",
        icon: "mdi-view-list",
      },
      {
        tab: "Fuentes de financiamiento",
        content: "FuenteFinanciamientoListComponent",
        icon: "mdi-bank",
      },
      {
        tab: "Organismos financieros",
        content: "OrganismoFinanciadorListComponent",
        icon: "mdi-home-city-outline",
      },
      {
        tab: "Convenio",
        content: "ConvenioListComponent",
        icon: "mdi-handshake-outline",
      },
    ];
  },
};
</script>

<style></style>
