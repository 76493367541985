<template>
  <div>
    <v-dialog persistent :max-width="650" v-model="modal">
      <v-card>
        <v-btn icon @click="cerrar()" color="secondary">
          <v-icon> mdi-window-close </v-icon>
        </v-btn>
        <v-row class="ma-2">
          <p class="text-h5 text-left mx-4 px-4">Agregar línea de trabajo</p>
        </v-row>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                class="mx-4"
                label="Estructura presupuestaria*"
                outlined
                v-model="form.estructura"
                :items="ctl_estructura_presupuestaria"
                item-text="nombre"
                item-value="id"
                :error-messages="estructuraErrors"
                @blur="$v.form.estructura.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="mx-4"
                label="Código*"
                outlined
                v-model="form.codigo"
                :error-messages="codigoErrors"
                @blur="$v.form.codigo.$touch()"
                @input="$v.form.codigo.$touch()"
                maxlength="3"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mx-4"
                label="Línea de trabajo*"
                outlined
                :maxlength="250"
                v-model="form.nombre"
                :error-messages="nombreErrors"
                @blur="$v.form.nombre.$touch()"
                @input="$v.form.nombre.$touch()"
                counter="250"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mx-4"
                label="Propósito*"
                outlined
                :maxlength="1024"
                height="470"
                v-model="form.proposito"
                :error-messages="propositoErrors"
                @blur="$v.form.proposito.$touch()"
                @input="$v.form.proposito.$touch()"
                counter="1024"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <section>
            <div class="d-flex justify-center mb-4">
              <v-btn text color="secondary" @click="cerrar()" outlined>
                Cancelar
              </v-btn>
              <v-col cols="1"></v-col>
              <v-btn color="secondary" @click="aceptar()"> Aceptar </v-btn>
            </div>
          </section>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
  required,
  maxLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";

const txtInput = helpers.regex('txtInput', /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 '"\n()/.,:;-]*$/)

export default {
  name: "LineaTrabajoFormComponent",
  props: {
    modal: {
      type: Boolean,
    },
  },
  validations: {
    form: {
      codigo: {
        required,
        numeric,
        maxLength: maxLength(3),
      },
      nombre: {
        required,
        txtInput,
        maxLength: maxLength(250),
      },
      estructura: {
        required,
      },
      proposito: {
        required,
        txtInput,
        maxLength: maxLength(1024),
      },
    },
  },
  data: () => ({
    form: {
      id: null,
      codigo: null,
      nombre: null,
      estructura: null,
      proposito: null,
    },
  }),
  methods: {
    ...mapActions("lineaTrabajoStore", ["getListaLineas", "storeLinea"]),
    ...mapActions("estructuraPresupuestariaStore", ["getCtlEstructuraPresupuestaria"]),
    async aceptar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const body = {
            codigo: this.form.codigo,
            linea_trabajo: this.form.nombre,
            id_estructura: this.form.estructura,
            proposito: this.form.proposito,
            anio: this.anioFiscal,
          };
          const response = await this.storeLinea(body);
          this.temporalAlert({
            show: true,
            type: "success",
            message: this.form.id
              ? "Se actualizó la información de la línea de trabajo"
              : "Línea de trabajo registrada",
          });
          await this.getListaLineas();
          this.cerrar();
        } catch (error) {}
      } else {
      }
    },
    cerrar() {
      this.$emit("cerrarModal");
      this.formClean();
    },
    formClean() {
      this.form.id = null;
      this.form.codigo = null;
      this.form.nombre = null;
      this.form.estructura = null;
      this.form.proposito = null;

      this.$v.$reset();
    },
  },
  computed: {
    ...mapState(["anioFiscal"]),
    ...mapState("estructuraPresupuestariaStore", ["ctl_estructura_presupuestaria"]),
    codigoErrors() {
      const errors = [];
      if (!this.$v.form.codigo.$dirty) return errors;
      !this.$v.form.codigo.required && errors.push("Código es obligatorio");
      !this.$v.form.codigo.maxLength &&
        errors.push("Longitud máxima de 3 caracteres");
      !this.$v.form.codigo.numeric && errors.push("Solo números enteros");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required && errors.push("Nombre es obligatorio");
      !this.$v.form.nombre.maxLength &&
        errors.push("Longitud máxima de 250 caracteres");
      !this.$v.form.nombre.txtInput &&
        errors.push("Permite solo caracteres alfabéticos");
      return errors;
    },
    estructuraErrors() {
      const errors = [];
      if (!this.$v.form.estructura.$dirty) return errors;
      !this.$v.form.estructura.required &&
        errors.push("Estructura es obligatorio");
      return errors;
    },
    propositoErrors() {
      const errors = [];
      if (!this.$v.form.proposito.$dirty) return errors;
      !this.$v.form.proposito.required &&
        errors.push("Propósito es obligatorio");
      !this.$v.form.proposito.maxLength &&
        errors.push("Longitud máxima de 1024 caracteres");
      !this.$v.form.proposito.txtInput &&
        errors.push("No utilice caracteres especiales en este campo");
      return errors;
    },
  },
  async created() {
    await this.getCtlEstructuraPresupuestaria();
  },
};
</script>
