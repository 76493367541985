<template>
  <div>
    <v-dialog persistent :max-width="600" v-model="modal">
      <v-card>
        <v-btn icon @click="cerrar()" color="secondary">
          <v-icon> mdi-window-close </v-icon>
        </v-btn>
        <v-row class="ma-2">
          <p class="text-h5 text-left mx-4 px-4">
            Agregar estructura presupuestaria
          </p>
        </v-row>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="mx-4 pt-4"
                label="Código*"
                outlined
                v-model="form.codigo"
                :error-messages="codigoErrors"
                @blur="$v.form.codigo.$touch()"
                @input="$v.form.codigo.$touch()"
                maxlength="3"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                class="mx-4"
                label="Estructura*"
                outlined
                auto-grow
                :maxlength="250"
                v-model="form.nombre"
                :error-messages="nombreErrors"
                @blur="$v.form.nombre.$touch()"
                @input="$v.form.nombre.$touch()"
                counter="250"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <section>
            <div class="d-flex justify-center mb-4">
              <v-btn text color="secondary" @click="cerrar()" outlined>
                Cancelar
              </v-btn>
              <v-col cols="1"/>
              <v-btn color="secondary" @click="aceptar()"> Aceptar </v-btn>
            </div>
          </section>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
  required,
  maxLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";

const txtInput = helpers.regex('txtInput', /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 '"\n()/.,:;-]*$/)

export default {
  name: "EstructuraPresupuestariaFormComponent",
  props: {
    modal: {
      type: Boolean,
    },
  },
  validations: {
    form: {
      codigo: {
        required,
        maxLength: maxLength(3),
        numeric,
      },
      nombre: {
        required,
        txtInput,
      },
    },
  },
  data: () => ({
    form: {
      id: null,
      codigo: null,
      nombre: null,
    },
  }),
  methods: {
    ...mapActions("estructuraPresupuestariaStore", [
      "getListaEstructuras",
      "storeEstructura",
    ]),
    async aceptar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const body = {
            codigo: this.form.codigo,
            nombre: this.form.nombre,
            anio: parseInt(this.anioFiscal),
          };
          const response = await this.storeEstructura(body);
          this.temporalAlert({
            show: true,
            type: "success",
            message: this.form.id
              ? "Se actualizó la información de la estructura"
              : "Estructura presupuestaria registrada",
          });
          await this.getListaEstructuras();
          this.cerrar();
        } catch (error) {}
      } else {
      }
    },
    cerrar() {
      this.$emit("cerrarModal");
      this.formClean();
    },
    formClean() {
      this.form.id = null;
      this.form.codigo = null;
      this.form.nombre = null;
      this.$v.$reset();
    },
  },
  computed: {
    ...mapState(['anioFiscal']),
    codigoErrors() {
      const errors = [];
      if (!this.$v.form.codigo.$dirty) return errors;
      !this.$v.form.codigo.required && errors.push("Codigo es obligatorio");
      !this.$v.form.codigo.maxLength &&
        errors.push("Longitud máxima de 3 caracteres");
      !this.$v.form.codigo.numeric &&
        errors.push("Solo se permiten números enteros");

      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required && errors.push("Nombre es obligatorio");

      !this.$v.form.nombre.txtInput &&
        errors.push("Permite solo caracteres alfabéticos");
      return errors;
    },
  },
};
</script>
