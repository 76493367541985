<template>
  <section>
    <v-btn class="secondary my-5" dark @click="agregar()"  v-if="!haveRole('ROLE_DIRECCION_COMPRAS')"> Agregar </v-btn>
    <DataTableComponent
      :headers="tituloCampos"
      :items="listadoConvenios"
      @paginar="paginate"
      v-models:pagina="paginaModel"
      v-models:select="porPaginaModel"
      :total_registros="filters.totalRows"
    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="eliminar(item)"  :disabled="haveRole('ROLE_DIRECCION_COMPRAS')">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </DataTableComponent>
    <!-- MODAL AGREGAR -->
    <ConvenioFormComponent :modal="modalAgregar" @cerrarModal="cerrarModal()" />
    <!-- MODAL ELIMINAR -->
    <ConfirmationDialogComponent
      :show="modalEliminar"
      title="¿Desea remover el convenio seleccionado ?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="cerrarModalEliminar()"
      @confirm="eliminarConvenio()"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ConvenioFormComponent from "./ConvenioFormComponent";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";
import DataTableComponent from "../../../../components/DataTableComponent.vue";

export default {
  name: "ConvenioListComponent",
  components: {
    ConvenioFormComponent,
    ConfirmationDialogComponent,
    DataTableComponent,
  },
  data: () => ({
    id: null,
    modalAgregar: false,
    modalEliminar: false,
    tituloCampos: [
      { text: "Código", value: "codigo" },
      {
        text: "Convenio",
        value: "nombre",
      },
      {
        text: "Fuente de financiamiento",
        value: "fuente_financiamiento",
      },

      { text: "Acciones", value: "acciones", align: "center" },
    ],
  }),

  computed: {
    ...mapState("convenioStore", ["listadoConvenios", "filters"]),
    ...mapState({
      idIntitucion: (state) => state?.selectedUnidad?.instituciones?.id,
    }),

    porPaginaModel: {
      get() {
        return this.filters.perPage;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },

  methods: {
    ...mapActions("convenioStore", ["getListaConvenios", "deleteConvenio"]),
    agregar() {
      this.modalAgregar = true;
    },
    eliminar(item) {
      this.id = item.id;
      this.modalEliminar = true;
    },
    async eliminarConvenio() {
      const response = await this.deleteConvenio(this.id);
      if (response.status === 204) {
        this.temporalAlert({
          show: true,
          message: "Convenio eliminado",
          type: "success",
        });
        await this.getListaConvenios({id_institucion: this.idIntitucion});
      }
      this.cerrarModalEliminar();
    },
    cerrarModal() {
      this.modalAgregar = false;
    },
    cerrarModalEliminar() {
      this.modalEliminar = false;
      this.formClean();
    },
    formClean() {
      this.id = null;
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      const filtros = {
        page: pagina,
        per_page: cantidad_por_pagina,
        id_institucion: this.idIntitucion,
      };
      await this.getListaConvenios(filtros);
    },
  },
  async created() {
    await this.getListaConvenios({id_institucion: this.idIntitucion});
  },
};
</script>

<style></style>
