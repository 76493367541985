<template>
  <section>
    <v-btn class="secondary my-5" dark @click="agregar()" v-if="!haveRole('ROLE_DIRECCION_COMPRAS')" > Agregar </v-btn>
    <DataTableComponent
      :headers="tituloCampos"
      :items="listadoEstructuras"
      @paginar="paginate"
      v-models:pagina="paginaModel"
      v-models:select="porPaginaModel"
      :total_registros="filters.totalRows"

    >
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="eliminar(item)"  :disabled="haveRole('ROLE_DIRECCION_COMPRAS')">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </DataTableComponent>

    <!-- MODAL AGREGAR -->
    <EstructuraPresupuestariaFormComponent
      :modal="modalAgregar"
      @cerrarModal="cerrarModal()"
    />
    <!-- MODAL ELIMINAR -->
    <ConfirmationDialogComponent
      :show="modalEliminar"
      title="¿Desea remover la estructura presupuestaria seleccionada ?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="cerrarModalEliminar()"
      @confirm="eliminarEstructuraPresupustaria()"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import EstructuraPresupuestariaFormComponent from "./EstructuraPresupuestariaFormComponent";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";
import DataTableComponent from "../../../../components/DataTableComponent.vue";
export default {
  name: "EstructuraPresupuestariaListComponent",
  components: {
    EstructuraPresupuestariaFormComponent,
    ConfirmationDialogComponent,
    DataTableComponent
  },
  data: () => ({
    id: null,
    modalAgregar: false,
    modalEliminar: false,
    filtros: {
      per_page: null,
      page: null,
    },
    tituloCampos: [
      { text: "Código", value: "codigo" },
      {
        text: "Estructura presupuestaria",
        value: "nombre",
      },
      { text: "Acciones", value: "acciones", align: "center" },
    ],
  }),

  computed: {
    ...mapState("estructuraPresupuestariaStore", ["listadoEstructuras", "filters"]),

    porPaginaModel: {
      get() {
        return this.filters.perPage;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },

  methods: {
    ...mapActions("estructuraPresupuestariaStore", [
      "getListaEstructuras",
      "deleteEstructura",
    ]),
    agregar() {
      this.modalAgregar = true;
    },
    eliminar(item) {
      this.id = item.id;
      this.modalEliminar = true;
    },
    async eliminarEstructuraPresupustaria() {
      const response = await this.deleteEstructura(this.id);
      if (response.status === 204) {
        this.temporalAlert({
          show: true,
          message: "Estructura presupuestaria eliminada",
          type: "success",
        });
        await this.getListaEstructuras();
      }
      this.cerrarModalEliminar();
    },
    cerrarModal() {
      this.modalAgregar = false;
    },
    cerrarModalEliminar() {
      this.modalEliminar = false;
      this.formClean();
    },
    formClean() {
      this.id = null;
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina} = params;
      const filtros = {
        page: pagina,
        per_page : cantidad_por_pagina,
      }
      await this.getListaEstructuras(filtros);
    }
  },
  async created() {
    await this.getListaEstructuras();
  },
};
</script>

<style></style>
